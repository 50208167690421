<template>
  <div class="model-comment" :id="`comment-${id}`" w100>
    <form class="model-comment-form" :id="`data-comment-${id}`">
      <slot></slot>
      <div class="model-comment-options" v-if="!noOptionsOn.includes(cfilter)">
        <a class="model-comment-options-button --submit" @click="sendComment(`data-comment-${id}`)">
          <span>Guardar</span>
          <iconic name="save" />
        </a>
      </div>
    </form>
  </div>
</template>

<script>
  export default {
    props: { post: Object },
    data: function () {
      return {
        id: this.$global.fastID(),
        current: null,
        noOptionsOn: ["approved", "pending", "admitted", "deserter"],
        cfilter: this.$route.query.filter,
      };
    },
    methods: {
      sendComment: async function (formId) {
        const _this = this;
        const commentbox = document.querySelector(`#${formId} .textarea-rich-box`);
        const _host = `${process.env.VUE_APP_API_HOST}admin/models/comments`;
        const _data = {
          cfilter: this.cfilter,
          model: this.post._id,
          commentbox: commentbox.innerHTML.trim(),
        };
        if (_data.commentbox) {
          this.dataSending();
          await this.$api
            .post(_host, _data)
            .then(function (response) {
              if (response.data.comment) {
                _this.dataSuccess(response.data);
              } else {
                _this.dataError(response.data.message);
              }
            })
            .catch(function (error) {
              _this.dataError(error);
            });
        }
      },
      dataSending: function () {
        const commentArea = document.querySelector(`#data-comment-${this.id}`);
        commentArea ? commentArea.classList.add("loading") : false;
      },
      dataSent: function () {
        const commentArea = document.querySelector(`#data-comment-${this.id}`);
        commentArea ? commentArea.classList.remove("loading") : false;
      },
      dataError: function (message) {
        this.dataSent();
        this.$swal.fire({
          title: "Error",
          html: message ? message : "Error desconocido",
          icon: "error",
          confirmButtonText: "Revisar",
          timerProgressBar: true,
          confirmButtonColor: "#bd0909",
        });
      },
      dataSuccess: function (data) {
        this.dataSent();
        this.$emit("newComment", data.comment);
        document.querySelector(`#data-comment-${this.id} .textarea-rich-box`).innerHTML = "";
      },
    },
  };
</script>

<style lang="scss">
  .model-comment {
    &-form {
      position: relative;
    }
    &-options {
      text-align: right;
      justify-content: flex-end;
      &:not(:active) {
        display: none;
      }
      margin-top: $mpadding/2;
      &-button {
        display: inline-block;
        padding: $mpadding/4 $mpadding/2;
        border-radius: $mpadding/4;
        cursor: pointer;
        user-select: none;
        span + i:not(:empty) {
          margin-left: $mpadding/2;
        }
        &:hover {
          opacity: 0.9;
        }
        &:active {
          opacity: 0.8;
        }
        &.--submit {
          background: $primary_color;
          color: $white;
        }
      }
    }
    &:focus-within &-options {
      display: flex;
    }
  }
</style>
